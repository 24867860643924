import React from 'react'
import styled from 'styled-components'

import { Container } from '../components/shared/container'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout hasBreadcrumb={false}>
    <SEO title="404: Not found" />
    <PageContainer>
      <div>
        <h1>PÁGINA NÃO ENCONTRADA</h1>
        <p>Não foi possível encontrar a página que você estava procurando. Isso ocorre porque:</p>
        <p>
          - Há um erro no endereço inserido em seu navegador da web. Verifique o endereço e tente
          novamente.
        </p>
        <p>- A página que você está procurando foi movida ou excluída.</p>
        <a href="/">Página inicial</a>
      </div>
    </PageContainer>
  </Layout>
)

const PageContainer = styled(Container)``

export default NotFoundPage
